import React, { useContext } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Media } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUser,
    faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { CommonContext } from "../../context";
import { Link } from "react-router-dom";

const UserProfileDropdown = () => {
    const { indexData, seoData, user, handleLogout } = useContext(CommonContext);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    const toggle = () => setDropdownOpen(!dropdownOpen);

    return (
        <div className="profile-dropdown d-flex align-items-center">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="profile-dropdown">
                <DropdownToggle
                    caret
                    tag="span"
                    style={{ cursor: "pointer" }}
                     className="profile-dropdown-toggle"
                >
                    <div className="profile-circle">
                        {user.name?.charAt(0).toUpperCase()}
                    </div>
                </DropdownToggle>
                <DropdownMenu right className="custom-dropdown">
                    <div className="user-info">
                        <strong>{user.name}</strong>
                    </div>
                    <DropdownItem divider />
                    <DropdownItem className="dropdown-item-custom">
                        <FontAwesomeIcon icon={faUser} className="menu-icon" />
                        <Link to="/profile" className="dropdown-item p-0">My Profile</Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem className="dropdown-item-custom logout" onClick={handleLogout}>
                        <FontAwesomeIcon icon={faSignOutAlt} className="menu-icon" /> <div className="dropdown-item p-0">Log out</div>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default UserProfileDropdown;
