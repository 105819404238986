import React, { Suspense, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { HelmetProvider } from "react-helmet-async";
import Header from "./component/common/Header";
import { CommonContextProvider } from "./context";
import Home from "./component/home";
import Footer from "./component/common/Footer";
import Contact from "./component/contact";
import About from "./component/about";
import Grievance from "./component/grievance";
import News from "./component/news";
import Events from "./component/events";
import Newsdetails from "./component/newsdetails";
import Eventdetails from "./component/eventdetails";
import Journal from "./component/journal";
import InstitutionalMembers from "./component/institutionalMembers";
import Branches from "./component/branches";
import Publications from "./component/publications";
import JournalDetails from "./component/journalDetails";
import Blogs from "./component/blogs";
import BlogDetails from "./component/blogsDetails";
import Article from "./component/article";
import PreLoader from "./component/common/PreLoader";
import Special from "./component/special";
import SpecialDetails from "./component/specialDetails";
import Articledetails from "./component/articledetails";
import NotFound from "./component/common/NotFound";
import SubmitArticles from "./component/submitarticles";
import MemberProfile from "./component/members/memberProfile";
import Book from "./component/book";
import BookDetails from "./component/bookDetails";
import Gallery from "./component/gallery";
import FileManager from "./component/fileManager";
import Courses from "./component/courses";
import CourseDetails from "./component/courseDetails";
import Registration from "./component/registration";
import PdfFlip from "./component/journalDetails/PdfFlip";
import Tender from "./component/tender";
import Icv from "./component/icv";
import Certification from "./component/certification";
import IndividualMember from "./component/individualMember";
import MemberPreview from "./component/members/memberProfile/memberPreview";
import Checkout from "./component/members/memberProfile/checkout";

const SignIn = React.lazy(() => import('./component/common/SignIn'));
const SignUp = React.lazy(() => import('./component/common/SignUp'));
const ForgotPassword = React.lazy(() => import('./component/common/ForgotPassword'));
const AboutFounder = React.lazy(() => import('./component/about/AboutFounder'));
const AboutSecretary = React.lazy(() => import('./component/about/AboutSecretary'));
const WhyJoin = React.lazy(() => import('./component/about/WhyJoin'));
const Council = React.lazy(() => import('./component/about/Council'));
const BlueBook = React.lazy(() => import('./component/about/BlueBook'));
const CMS = React.lazy(() => import('./component/common/CMS'));

const App = () => {
  const [userProfile, setUserProfile] = useState(null);
  const location = useLocation();
  return (
    <CommonContextProvider setUserProfile={setUserProfile}>
      <Suspense fallback={<PreLoader />}>
        <HelmetProvider>
          <>
            {!['/checkout'].includes(location.pathname) && (
              <>
                <Header />
              </>
            )}
            <Routes>
              <Route path="/signin" exact element={<SignIn />} />
              <Route path="/signup" exact element={<SignUp />} />
              <Route
                path="/forgot-password"
                exact
                element={<ForgotPassword />}
              />
              <Route path="/" exact element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/about-founder" element={<AboutFounder />} />
              <Route path="/about-secretary" element={<AboutSecretary />} />
              <Route path="/why_join_iov" element={<WhyJoin />} />
              <Route path="/council" element={<Council />} />
              <Route path="/blue-book" element={<BlueBook />} />
              <Route path="/join-iov" component={() => <div>Join IOV</div>} />
              <Route
                path="/institutional-members"
                element={<InstitutionalMembers />}
              />
              <Route path="/grievance" element={<Grievance />} />
              <Route path="/e-journal" element={<Journal />} />
              <Route path="/e-journal-details" element={<JournalDetails />} />
              <Route path="/e-journal-read" element={<PdfFlip />} />
              <Route path="/e-library" element={<Publications />} />
              <Route path="/events" element={<Events />} />
              <Route path="/event-details" element={<Eventdetails />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/submitarticles" element={<SubmitArticles />} />
              <Route path="/news/:slug" element={<News />} />
              <Route path="/news-details" element={<Newsdetails />} />
              <Route path="/branches" element={<Branches />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blogs-details" element={<BlogDetails />} />
              <Route path="/articles" element={<Article />} />
              <Route path="/article-details" element={<Articledetails />} />
              <Route path="/publication" element={<Book />} />
              <Route path="/book-details" element={<BookDetails />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/tender" element={<Tender />} />
              <Route
                path="/special-certificate-program"
                element={<Special />}
              />
              <Route path="/program-details" element={<SpecialDetails />} />
              <Route path="/page/:slug" element={<CMS />} />
              <Route exact path="/profile" element={<MemberProfile />} />
              <Route exact path="/file-manager" element={<FileManager />} />
              <Route exact path="/courses" element={<Courses />} />
              <Route exact path="/course-details" element={<CourseDetails />} />
              <Route exact path="/course-register" element={<Registration />} />
              <Route exact path="/icv" element={<Icv />} />
              <Route exact path="/list_of_members" element={<IndividualMember />} />
              <Route exact path="/preview" element={<MemberPreview />} />
              <Route exact path="/checkout" element={<Checkout />} />
              <Route exact path="/active-professional-valuers" element={<Certification />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
            {!['/checkout'].includes(location.pathname) && (
              <>
                <Footer />
              </>
            )}
          </>
        </HelmetProvider>
      </Suspense>
    </CommonContextProvider>
  );
};

export default App;
