import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Input, Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import {
  faClock,
  faEnvelope as faEnvelopeSolid,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import "../../assetss/scss/Footer.scss";
// import InstitutionalMembers from "../home/InstitutionalMembers";
import { Link, useNavigate } from "react-router-dom";
import { CommonContext } from "../../context";
import commonApi from "../../utiles/api";

const Footer = () => {
  const { indexData } = useContext(CommonContext);
  const [formTemp, setFormTemp] = useState({
    email: "",
  });
  const [submitError, setSubmitError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const emailInput = document.getElementById('email');
            if (emailInput) {
              emailInput.focus();
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    const newsletterForm = document.getElementById('newsletter-form');
    if (newsletterForm) {
      observer.observe(newsletterForm);
    }

    return () => {
      if (newsletterForm) {
        observer.unobserve(newsletterForm);
      }
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormTemp((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const validateForm = () => {
    const { email } = formTemp;

    if (!email) {
      return 'Email is required';
    }
    return '';
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();

    if (validationError) {
      setSubmitError(validationError);
      return;
    }

    setLoading(true);
    setSubmitError('');
    setSubmitSuccess('');

    try {
      const response = await commonApi.post('home/newsletter', formTemp);
      if (response.data.s === 1) {
        setSubmitSuccess(response.data.msg);
        setTimeout(() => setSubmitSuccess(''), 3000);
      } else {
        setSubmitError(response.data.msg || 'Failed to change password');
      }
    } catch (error) {
      setSubmitError(error?.response?.data?.msg || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  const Logo =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "logo")
      ?.option_value;
  const fbLink =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "fb_link")
      ?.option_value;
  const xLink =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "x_link")
      ?.option_value;
  const linkedinLink =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "linkedin_link")
      ?.option_value;
  const emailContact =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "contact_email")
      ?.option_value;
  const cAddress =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "contact_address")
      ?.option_value;
  const cPhone =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "contact_phone")
      ?.option_value;
  const whatsapp =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "wa_number")
      ?.option_value;

  return (
    <>
      {/* <InstitutionalMembers /> */}
      <footer className="footer-container">
        <Container className="footer-overlay">
          <Row className="justify-content-center text-center mb-4">
            <Col md="2">
              <Button className="footer-button" onClick={() => navigate("/signup")}>BE A MEMBER</Button>
            </Col>
            <Col md="2">
              <Button className="footer-button" onClick={() => navigate("/contact")}>CONTACT</Button>
            </Col>
            <Col md="2">
              <Button className="footer-button">QUICK LINKS</Button>
            </Col>
            <Col md="2">
              <Button className="footer-button">INFORMATION</Button>
            </Col>
            <Col md="2">
              <Button className="footer-button"><a href="#newsletter-form">SUBSCRIBE US</a></Button>
            </Col>
          </Row>
          <Row className="text-white mb-4">
            <Col md="4" className="mb-4">
              <h5>Contact Us</h5>
              <p>{cAddress}</p>
              <p className="m-0">
                <FontAwesomeIcon icon={faPhone} /> {cPhone}
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelopeSolid} /> {emailContact}
              </p>
              <img
                src={indexData?.settings_path + "/" + Logo}
                alt="IOV Logo"
                className="footer-logo d-none"
              />
              <p>
                Public Relation Officer
              </p>
              <p className="m-0">
                <FontAwesomeIcon icon={faPhone} /> {'+91 9289207007 '}
              </p>
              <p className="m-1">
                or
              </p>
              <p className="m-0">
                <FontAwesomeIcon icon={faEnvelopeSolid} /> {'pro@iov.co.in'}
              </p>
              <p className="m-0">
                <FontAwesomeIcon icon={faClock} />  Mon - Sat: 10AM - 6PM
                (1st & 3rd Saturday Off).
              </p>
            </Col>
            <Col md="3" className="mb-4 col-6">
              <h5>Main Menu</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="/page/about"> About Us</Link>
                </li>
                <li>
                  <Link to="/page/about-secretary"> Hon. Gen. Secretary Desk</Link>
                </li>
                <li>
                  <Link to="/page/council"> Council 2022-2024</Link>
                </li>
                <li>
                  <Link to="/page/why_join_iov"> Join IOV</Link>
                </li>
                <li>
                  <Link to="/branches"> IOV Branches</Link>
                </li>
              </ul>
            </Col>
            <Col md="3" className="mb-4 col-6">
              <h5>Support</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="/grievance"> Grievance</Link>
                </li>
                {/* <li>
                  <Link to="/institutional-members"> List of Member</Link>
                </li> */}
                <li>
                  <Link to="/news"> News</Link>
                </li>
                <li>
                  <Link to="/events"> Events</Link>
                </li>
                <li>
                  <Link to="/gallery"> Gallery</Link>
                </li>
                <li>{/* <Link to="#"> Privacy Policy</Link> */}</li>
              </ul>
            </Col>
            <Col md="2" className="mb-4 col-6">
              <h5>Company</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="/page/privacy_policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/sitemap">Sitemap</Link>
                </li>
                <li>
                  <Link to="/page/disclaimer">Disclaimer</Link>
                </li>
              </ul>
            </Col>
          </Row>
          <hr className="footer-divider" />
          <Row className="d-flextext-white mt-4 footer-bottom col-12 ">
            <a
              href={`https://wa.me/${whatsapp}`}
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-icon"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
            <Col md="6" className=" justify-content-center order-mobile-2">
              <p>
                {/* Terms of Use &nbsp; | &nbsp; Privacy Policy &nbsp; | &nbsp; Data
                Security &nbsp; | &nbsp; Cookie Setting */}
                <Link to="/page/pc_goel_memorial_donation_fund">
                  P.C. Goel Memorial Lecture Fund
                </Link>
              </p>
              <br />
              <h6>©2024 All Rights Reserved. Terms & Privacy Policies</h6>
            </Col>

            <Col md="6" className=" newsletter-container order-mobile-1">
              <Row className="d-flex col-12">
                <Col md="12" className="text-right">
                  <ul class="social-icons d-flex ml-3">
                    <li class="icon-container">
                      <Link
                        to={fbLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faFacebookF} size="1x" />
                      </Link>
                    </li>
                    <li class="icon-container">
                      <Link
                        to={xLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faTwitter} size="1x" />
                      </Link>
                    </li>
                    <li class="icon-container">
                      <Link
                        to={linkedinLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faLinkedinIn} size="1x" />
                      </Link>
                    </li>
                    <li class="icon-container">
                      <Link to={emailContact}>
                        <FontAwesomeIcon icon={faEnvelopeSolid} size="1x" />
                      </Link>
                    </li>
                  </ul>
                </Col>
              </Row>
              {submitSuccess && <Alert color="success">{submitSuccess}</Alert>}
              {submitError && <Alert color="danger">{submitError}</Alert>}

              <Col md="12" className="newsletter-title-footer">
                <h4>Information and Updates</h4>
              </Col>
              <Form
                className="w-100"
                onSubmit={handleSubmit}
                id="newsletter-form"
              >
                <Input
                  type="email"
                  placeholder="Enter Your Email Id"
                  className="newsletter-input mr-2"
                  name="email"
                  id="email"
                  value={formTemp.email}
                  onChange={handleChange}
                  required
                />
                <Button className="subscribe-button btn-grad m-0" type="submit" disabled={loading}>Subscribe</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
